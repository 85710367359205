import ReactHlsPlayer from "react-hls-player";
import { ReactNetflixPlayer } from "react-netflix-player";

function App() {
    const queryParameters = new URLSearchParams(window.location.search)
    const url = queryParameters.get("url")
  return (
    <div className="App">
       <ReactHlsPlayer
        src="https://cloud.animeflixplus.com//upload/videos/test/video.m3u8"
        autoPlay={true}
        controls={true}
        height="100%"
      />
    </div>
  );
}

export default App;
